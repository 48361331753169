import jQuery from 'jquery/dist/jquery';
import Swal from 'sweetalert2';
import * as bootstrap from 'bootstrap'
import Utils from '../../../../../utils'
import ErpOrderLineItemEntity from "../../../order_line_item/entity";
import UtilsErp from "../../../../../utils/erp";
import UtilsEntity from "../../../../../utils/entity";
import DataTable from 'datatables.net-dt';

export default class ErpOrderGeneral {
    private parent: any;
    private entity = "erp/order_line_items";
    private toastr: any;
    private datatable: any = null;
    private datatableElem: any;
    private editModal: bootstrap.Modal;
    private data: any = {}
    private currentStockData: any = {}
    private currentLineItemMapIds = []
    private currentLineItemMapSerials = []
    private currentProductNumber = ""
    mode: string;

    constructor(parent: any, mode = "show") {
        this.mode = mode
        this.parent = parent
        this.toastr = parent.toastr
        this.currentStockData = {}
        this.datatableElem = jQuery(".datatables-erp-order-general")
        this.editModal = new bootstrap.Modal((document.querySelector("#editErpOrderLineItemModal") as HTMLElement));
        this.bindListeners();
    }

    async getEntityData(elem: any) {
        //@ts-ignore
        const d = {...ErpOrderLineItemEntity.getEntityData(elem), orderId: this.parent.id, errors: []}

        let mapRows = Array.from(document.querySelectorAll("#erp_order_line_item_add_warehouse_list_table tbody tr"));
        mapRows = mapRows.filter((map => {
            //@ts-ignore
            const input = map.querySelector("input") as HTMLInputElement;
            const inputValue = parseInt(input.value)
            return inputValue > 0
        }));
        let ids: any[] = [];
        let serials: any[] = []
        if (d.serials && d.serials.length > 0) {
            const warehouseR = await UtilsErp.productMappingsBySerial(this.parent.customerId || this.parent.data.orderCustomer[0].customerId, d.productId.toLowerCase(), d.serials[0]);
            if (warehouseR.data) {
                const tmpData = warehouseR.data[0];
                if (!tmpData || (tmpData.reserved && tmpData.reserved[0] && tmpData.reserved[0].quantity > 0)) {
                    return {errors: ["Serial currently not available"]}
                }
                ids = ids.concat(tmpData.id)
                serials = serials.concat(tmpData.serials)
            }
        } else {
            for (const map of mapRows) {
                //@ts-ignore
                const input = map.querySelector("input") as HTMLInputElement;
                const quantity = parseInt(input.value)
                const warehouseId = input.getAttribute("data-warehouse-id");
                const warehouseLocationId = input.getAttribute("data-warehouse-location-id");
                const warehouseLocationPalletId = input.getAttribute("data-warehouse-location-pallet-id");
                //@ts-ignore
                let selectedSerials = Array.from(input.closest(".row").querySelectorAll(".form-select option:checked")).map(s => s.value);
                const customerId = this.parent.customerId || this.parent.data.orderCustomer[0].customerId;
                // @ts-ignore
                const warehouseR = await UtilsErp.productMapById(d.productId.toLowerCase(), warehouseLocationId, warehouseLocationPalletId, customerId);
                console.log("selectedSerials", selectedSerials, warehouseR)
                if (warehouseR.data) {
                    if (!selectedSerials || selectedSerials.length === 0) {
                        const tmpData = warehouseR.data.slice(0, quantity);
                        const tmpIds = tmpData.map(d => d.id);
                        const tmpSerials = tmpData.map(d => d.serials);
                        ids = ids.concat(tmpIds)
                        serials = serials.concat(tmpSerials)
                    } else {
                        selectedSerials.forEach((sn: string) => {
                            const found = warehouseR.data.filter(t => t.serials[0] === sn)[0]
                            if (found) {
                                selectedSerials = selectedSerials.filter(s => s !== sn);
                                ids.push(found.id);
                                serials.push(found.serials);
                            }
                        })
                    }
                }
            }
        }
        let payloadDone = false
        if (this.parent.data && this.parent.data.lineItems) {
            const existingLineItem = this.parent.data.lineItems.filter((item: any) => {
                return item.productId === d.productId
            })[0];
            if (existingLineItem) {
                d.id = existingLineItem.id;
                d.quantity = d.quantity + existingLineItem.quantity;
                payloadDone = true;
                //@ts-ignore
                d.payload = {
                    ...this.data.payload,
                    kind2Serials: existingLineItem.payload.kind2Serials ? existingLineItem.payload.kind2Serials.concat(serials.flat()) : [],
                    kind2WarehouseLocationMappingIds: existingLineItem.payload.kind2WarehouseLocationMappingIds.concat(ids),
                    kind2WarehouseLocationMappingSerials: existingLineItem.payload.kind2WarehouseLocationMappingSerials.concat(serials),
                    productNumber: this.currentProductNumber
                }
            }
        }
        if (!payloadDone) {
            //@ts-ignore
            d.payload = {
                ...this.data.payload,
                kind2Serials: this.data.payload && this.data.payload.kind2Serials ? this.data.payload.kind2Serials.concat(serials.flat()) : serials.flat(),
                kind2WarehouseLocationMappingIds: ids,
                kind2WarehouseLocationMappingSerials: serials,
                productNumber: this.currentProductNumber
            }
        }
        //@ts-ignore
        d.totalPrice = d.unitPrice * d.quantity;
        return d;
    }

    getEntityDataPallet(elem: any) {
        return {
            warehouseId: elem.querySelector("#erp_pallet_warehouse_id option:checked")?.value,
            warehouseLocationPalletId: elem.querySelector("#erp_pallet_warehouse_location_pallet_id option:checked")?.value,
        }
    }

    async submitLineItem() {
        const elem = document.querySelector("#addErpOrderLineItemForm") as HTMLFormElement;
        const valid = elem.checkValidity();
        if (valid) {
            await Utils.showLoader();
            const bsElem = bootstrap.Offcanvas.getInstance((document.querySelector("#offcanvasAddErpOrderLineItem") as HTMLElement))

            if (this.mode === "show") {
                const entityData = await this.getEntityData(elem)
                if (!entityData || (entityData.errors && entityData.errors.length > 0)) {
                    this.toastr.error(`This serial is currently unavailable.`, `${Utils.translate('generic.failed')}`);
                    await Utils.hideLoader();
                } else {

                    console.log("entityData", entityData)
                    await Utils.entity.upsert(entityData, this.entity)
                    this.toastr.success(`${Utils.translate('erp.lineItem.name')} ${Utils.translate('generic.saved')}`, `${Utils.translate('generic.success')}`)
                    const table = document.querySelector("#erp_order_line_item_add_warehouse_list_table tbody") as HTMLElement;
                    table.innerHTML = "";
                    await Utils.hideLoader();
                    await this.parent.getEntity()
                    if (bsElem) {
                        bsElem.hide();
                    }
                    jQuery("#new_erp_order_line_item_product_id").val("").trigger("change").html("");
                    elem.reset();
                }
            } else {
                const entityData = await this.getEntityData(elem);
                if (entityData.errors.length > 0) {
                    this.toastr.error(`${entityData.errors.map((e: any) => {
                        return e
                    }).join(", ")}`, `${Utils.translate('generic.failed')}`);
                    await Utils.hideLoader();
                } else if (entityData.serials && entityData.serials.length > 0 && entityData.quantity !== entityData.serials.length) {
                    this.toastr.error(`Quantity does not match serial count.`, `${Utils.translate('generic.failed')}`)
                    await Utils.hideLoader();
                } else {
                    const r = await this.parent.addLineItem(entityData)
                    if (r.status === 200) {
                        await this.update(r.data, false)
                        if (bsElem) {
                            bsElem.hide();
                        }
                        this.toastr.success(`${Utils.translate('erp.lineItem.name')} ${Utils.translate('generic.saved')}`, `${Utils.translate('generic.success')}`)
                        await Utils.hideLoader();
                        const tbodytable = document.querySelector("#erp_order_line_item_add_warehouse_list_table tbody") as HTMLElement;
                        tbodytable.innerHTML = "";
                        jQuery("#new_erp_order_line_item_product_id").val("").trigger("change").html("");
                        elem.reset();
                    } else {
                        this.toastr.error(`${r.data.errors.map((e: any) => {
                            return e.message
                        }).join(", ")}`, `${Utils.translate('generic.failed')}`)
                        await Utils.hideLoader();
                    }
                }
            }
        }
    }

    async submitLinePallet() {
        const elem = document.querySelector("#addErpOrderWarehouseLocationPalletForm") as HTMLFormElement;
        const valid = elem.checkValidity();
        if (valid) {
            await Utils.showLoader();
            const bsElem = bootstrap.Offcanvas.getInstance((document.querySelector("#offcanvasAddErpOrderPallet") as HTMLElement))

            const entityData = this.getEntityDataPallet(elem);
            let r = null;
            console.log("AAA", this.parent)
            if (this.mode === "show") {
                r = await this.parent.addLinePallet(entityData, this.parent.id)
            } else {
                r = await this.parent.addLinePallet(entityData)
            }
            if (r && r.status === 200) {
                if (this.mode === "show") {
                    await this.parent.getEntity()
                } else {
                    await this.update(r.data, false)
                }
                if (bsElem) {
                    bsElem.hide();
                }
                this.toastr.success(`${Utils.translate('erp.lineItem.name')} ${Utils.translate('generic.saved')}`, `${Utils.translate('generic.success')}`)
                await Utils.hideLoader();
                jQuery("#erp_pallet_warehouse_location_pallet_id").val("").trigger("change");
                elem.reset();
            } else {
                this.toastr.error(`${r.data.errors.map((e: any) => {
                    return e.message
                }).join(", ")}`, `${Utils.translate('generic.failed')}`)
                await Utils.hideLoader();
            }
        }
    }

    bindListeners() {
        (document.querySelector("#addErpOrderLineItemForm") as HTMLFormElement).addEventListener("submit", async (e: any) => {
            e.preventDefault();
            await this.submitLineItem()
        });
        (document.querySelector("#addErpOrderWarehouseLocationPalletForm") as HTMLFormElement).addEventListener("submit", async (e: any) => {
            e.preventDefault();
            await this.submitLinePallet()
        });

        (document.querySelector("#editErpOrderLineItemForm") as HTMLFormElement).addEventListener("submit", async (e) => {
            e.preventDefault();
            const elem = document.querySelector("#editErpOrderLineItemForm") as HTMLFormElement;
            const valid = elem.checkValidity();
            if (valid) {
                const entityData = await this.getEntityData(elem);
                if (entityData.quantity > this.data.quantity + this.currentStockData.stock) {
                    this.toastr.error(`Customer does not have ${entityData.quantity} available. Currently available: ${this.data.quantity + this.currentStockData.stock}`, `${Utils.translate('generic.failed')}`)
                } else {
                    const r = await Utils.entity.upsert(entityData, this.entity)
                    if (r.status === 200) {
                        await this.parent.getEntity()
                        const bsElem = bootstrap.Modal.getInstance((document.querySelector("#editErpOrderLineItemModal") as HTMLElement))
                        if (bsElem) {
                            bsElem.hide();
                        }
                        this.toastr.success(`${Utils.translate('erp.lineItem.name')} ${Utils.translate('generic.saved')}`, `${Utils.translate('generic.success')}`)
                    }
                }
            }
        });

        jQuery("#new_erp_order_line_item_product_id").on("select2:select", (e: any) => {
            this.currentLineItemMapSerials = e.params.data.data.serials
            this.currentLineItemMapIds = e.params.data.data.ids;
            this.currentProductNumber = e.params.data.data.product.number;
            (document.querySelector("#addErpOrderLineItemForm #erp_order_line_item_unitPrice") as HTMLInputElement).value = e.params.data.data.product.price.gross;
        })


        this.datatableElem.delegate(".edit-record", "click", async (e: any) => {
            const id = e.currentTarget.getAttribute("data-id")
            const requestData = await Utils.entity.get(id, this.entity)
            if (requestData.status === 200) {
                const data = requestData.data[0]
                const form = (document.querySelector("#editErpOrderLineItemForm") as HTMLFormElement);
                Object.keys(data).forEach((key) => {
                    const elem = form.querySelector(`#erp_order_line_item_${key}`) as HTMLInputElement | null
                    if (elem && data[key]) {
                        elem.value = data[key]
                    }
                })
                const $newOption = jQuery("<option selected='selected'></option>").val(data.product[0].id).text(data.product[0].name)
                jQuery("#editErpOrderLineItemForm #erp_order_line_item_product_id").append($newOption).trigger('change');


                // @ts-ignore
                this.editModal.show();
                this.data = data;
                const productId = data.product[0].id;
                const stock = await UtilsErp.productStock(this.parent.data.orderCustomer[0].customerId, productId);
                if (stock && stock.status === 200) {
                    this.currentStockData = stock.data[0]
                    this.currentLineItemMapSerials = data.payload.kind2WarehouseLocationMappingSerials.concat(this.currentStockData.serials)
                    this.currentLineItemMapIds = data.payload.kind2WarehouseLocationMappingIds.concat(this.currentStockData.ids);
                }
            }

        });

        this.datatableElem.delegate(".delete-record", "click", async (e: any) => {
            const target = e.currentTarget;
            e.preventDefault();
            const id = e.currentTarget.getAttribute("data-id");
            const isNew = target.getAttribute("data-is-new")
            if (!isNew) {
                const confirm = await Swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Yes, delete it!',
                    customClass: {
                        confirmButton: 'btn btn-primary me-3',
                        cancelButton: 'btn btn-label-secondary'
                    },
                    buttonsStyling: false
                })
                if (confirm.value === true) {
                    const r = await Utils.entity.destroy(id, this.entity);
                    if (r.status === 200) {
                        this.toastr.success(`${Utils.translate('erp.lineItem.name')} ${Utils.translate('generic.deleted')}`, `${Utils.translate('generic.success')}`)
                    } else {
                        this.toastr.error(`${Utils.translate('erp.lineItem.name')} ${Utils.translate('generic.messages.not_deleted')}`, `${Utils.translate('generic.error')}`)
                    }
                    await this.parent.getEntity()
                }
            } else {
                const r = await this.parent.removeLineItem({id: id})
                if (r.status === 200) {
                    await this.update(r.data, false)
                    this.toastr.success(`${Utils.translate('erp.lineItem.name')} ${Utils.translate('generic.deleted')}`, `${Utils.translate('generic.success')}`)
                    await Utils.hideLoader();
                }
            }
        });

        /*(document.querySelector("#erp_order_line_item_tableall") as HTMLInputElement).addEventListener("click", (e) => {
            const target = e.target as HTMLInputElement;
            target.checked = !target.checked
            document.querySelectorAll("input[id^='erp_order_line_item_table_']").forEach((cb: any) => {
                cb.checked = target.checked
            })
        })*/
    }

    createDataTable() {
        this.datatable = new DataTable(".datatables-erp-order-general", {
            processing: true,
            layout: {
                topEnd: ['search', 'buttons']
            },
            language: {
                sLengthMenu: '_MENU_',
                search: '',
                searchPlaceholder: `${Utils.translate('generic.search')}...`,
                "zeroRecords": `${Utils.translate('generic.datatable.no_results')}`,
                "emptyTable": `${Utils.translate('generic.datatable.no_results')}`,
                "paginate": {
                    "first": `${Utils.translate('generic.datatable.pagination.first')}`,
                    "last": `${Utils.translate('generic.datatable.pagination.last')}`,
                    "next": `${Utils.translate('generic.datatable.pagination.next')}`,
                    "previous": `${Utils.translate('generic.datatable.pagination.previous')}`
                },
                "info": `${Utils.translate('generic.datatable.info.info')}`,
                "infoEmpty": `${Utils.translate('generic.datatable.info.empty')}`,
                "infoFiltered": `${Utils.translate('generic.datatable.info.filtered')}`,
            },
            buttons: [
                {
                    text: `<i class="ti ti-plus me-0 me-sm-1 ti-xs"></i><span class="d-none d-sm-inline-block">${Utils.translate('generic.add')}</span>`,
                    className: 'dt-button add-new btn btn-primary',
                    attr: {
                        'data-bs-toggle': 'offcanvas',
                        'data-bs-target': '#offcanvasAddErpOrderLineItem'
                    }
                },
                {
                    text: `<i class="ti ti-plus me-0 me-sm-1 ti-xs"></i><span class="d-none d-sm-inline-block">${Utils.translate('generic.add')} ${Utils.translate('erp.warehouse_location_pallet.name')}</span>`,
                    className: 'dt-button add-new btn btn-primary',
                    attr: {
                        'data-bs-toggle': 'offcanvas',
                        'data-bs-target': '#offcanvasAddErpOrderPallet'
                    }
                }
            ]
        });
    }

    async getSubentities() {
        const r = await UtilsEntity.getAll("product_attributes");
        if (r.status === 200) {
            return r.data;
        } else {
            return {}
        }
    }

    async update(data: any, enableActions = true) {
        if (this.datatable) {
            this.datatable.destroy();
        }
        await Utils.updateElements(data, '', (document.querySelector("#erp_order_general") as HTMLElement))
        const table = document.querySelector(".datatables-erp-order-general tbody") as HTMLElement;
        table.innerHTML = "";
        const subEntries = await this.getSubentities();
        for (const lineItem of data.lineItems) {
            let attributes = {}
            if (lineItem.payload.kind2WarehouseLocationMappingIds && lineItem.payload.kind2WarehouseLocationMappingIds.length > 0) {
                const mapDataReply = await UtilsEntity.getMultiple(lineItem.payload.kind2WarehouseLocationMappingIds, 'erp/warehouse_location_product_mappings');
                for(const mapData of mapDataReply.data) {
                    if (mapData.payload) {
                        Object.keys(mapData.payload).forEach((key: string) => {
                            const v = mapData.payload[key]
                            if (v && v.length > 0) {
                                // @ts-ignore
                                if (!attributes[key]) {
                                    // @ts-ignore
                                    attributes[key] = []
                                }
                                // @ts-ignore
                                attributes[key].push(v)
                            }
                        })
                    }
                }
            }
            let serials = [];
            if (lineItem.payload.kind2Serials) {
                serials = lineItem.payload.kind2Serials
            }
            if (lineItem.payload.kind2WarehouseLocationMappingSerials) {
                serials = lineItem.payload.kind2WarehouseLocationMappingSerials.map(s => Array.isArray(s) ? s[0] : s).filter(s => s)
            }
            let tr = `<tr>
                <!--<td><input type="checkbox" id="erp_order_line_item_table_${lineItem.id}" /> </td>!-->
                <td>${lineItem.quantity}</td>
                <td>${lineItem.label}</td>
                <td class="${!window.siteFeatures || !siteFeatures.wms ? 'd-none' : ''}">${Utils.translate(`erp.lineItem.status.${lineItem.wmsStatus}`)}</td>
                <td>${serials.join(", ")}</td>
                <!--<td>${lineItem.price.unitPrice}€</td>
                <td>${lineItem.price.taxRules[0].taxRate}%</td>
                <td>${lineItem.price.totalPrice}€</td>!-->`

            subEntries.forEach((key: any) => {
                const currentValue = attributes[key.name] ? attributes[key.name].filter(s => s).join(", ") : ''
                tr += `<td>${currentValue}</td>`
            })
            tr += `<td><div class="d-flex align-items-center"> `
            if (lineItem.wmsStatus === "NEW" || !lineItem.wmsStatus) {
                if (enableActions) {
                    tr += `<!--<a href="#" data-id="${lineItem.id}" class="text-body edit-record"> <i class="ti ti-edit ti-sm me-2"></i> </a>!--> <a href="#" data-id="${lineItem.id}" class="text-body delete-record"> <i class="ti ti-trash ti-sm mx-2"></i> </a>`
                } else {
                    tr += `<a href="#" data-is-new="true" data-id="${lineItem.id}" class="text-body delete-record"> <i class="ti ti-trash ti-sm mx-2"></i> </a>`

                }
            }
            tr += `</div></td>
            </tr>`
            table.innerHTML = table.innerHTML + tr;
        }
        this.createDataTable();
    }
}
